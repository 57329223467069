import { CountryFiltersActionTypes, SET_COUNTRIES_PAGE } from "../types/actions";
import { CountryFilters } from "../types/CountryFilters";

const countryFiltersReducerDefaultState: CountryFilters = {
    page: 1
};

const countryFiltersReducer = (
  state = countryFiltersReducerDefaultState,
  action: CountryFiltersActionTypes
): CountryFilters => {
  switch (action.type) {
    case SET_COUNTRIES_PAGE:
      return { ...state, page: action.page };
    default:
      return state;
  }
};

export { countryFiltersReducer };