import { 
  MediaActionTypes, 
  SET_MEDIA_DATA, 
  SET_SELECTED_MEDIA, 
  CLEAR_SELECTED_MEDIA 
} from "../types/actions";
import { MediaData } from "../types";
import { emptyMedia } from "common/models/emptyTemplates";

const mediaReducerDefaultState: MediaData = {
  data: [],
  currentPage: 1,
  lastPage: 1,
  totalCount: 0,
  selectedMedia: {...emptyMedia}
};

const mediaReducer = (
  state = mediaReducerDefaultState,
  action: MediaActionTypes
): MediaData => {
  switch (action.type) {
    case SET_MEDIA_DATA:
      return { ...state, ...action.mediaData };
    case SET_SELECTED_MEDIA:
      return { 
        ...state,
        selectedMedia: action.media
       };
    case CLEAR_SELECTED_MEDIA:
      return { 
        ...state,
        selectedMedia: {...emptyMedia}
       };   
    default:
      return state;
  }
};

export { mediaReducer };