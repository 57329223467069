import { CategoryFiltersActionTypes, SET_CATEGORIES_PAGE, SET_CATEGORIES_FILTERS, CHANGE_CATEGORIES_FILTERS } from "../types/actions";
import { CategoryFilters } from "../types";

const categoryFiltersReducerDefaultState: CategoryFilters = {
    page: 1,
    selectedCountry: null, 
    countries: []
};

const categoryFiltersReducer = (
  state = categoryFiltersReducerDefaultState,
  action: CategoryFiltersActionTypes
): CategoryFilters => {
  switch (action.type) {
    case SET_CATEGORIES_PAGE:
      return { ...state, page: action.page };
    case SET_CATEGORIES_FILTERS:
      return { ...state, 
        countries: action.filters.countries
      };
    case CHANGE_CATEGORIES_FILTERS:  
      return { ...state, ...action.selectedFilters, page: 1 };
    default:
      return state;
  }
};

export { categoryFiltersReducer };