import {Show, PaginatedData, PlayMedia } from "common/models";
import { ShowFilters, SelectedShowFilters } from ".";

export const SET_SHOWS_DATA = 'SET_SHOWS_DATA';
export const SET_SHOW_MEDIA = 'SET_SHOW_MEDIA';
export const SET_FEATURED_SHOWS = 'SET_FEATURED_SHOWS';
export const SET_SHOWS_PAGE = 'SET_SHOWS_PAGE';
export const SET_SHOWS_FILTERS = 'SET_SHOWS_FILTERS';
export const CHANGE_SHOWS_FILTERS = 'CHANGE_SHOWS_FILTERS';

export interface SetShowsAction {
  type : typeof SET_SHOWS_DATA;
  showsData : PaginatedData < Show >;
}

export interface SetShowMediaAction {
  type : typeof SET_SHOW_MEDIA;
  media : Array<PlayMedia>;
}

export interface SetFeaturedShowsAction {
  type : typeof SET_FEATURED_SHOWS;
  featuredShows : Array<Show>;
}

export interface SetShowsPageAction {
  type : typeof SET_SHOWS_PAGE;
  page : number;
}

export interface SetShowsFiltersAction {
  type : typeof SET_SHOWS_FILTERS;
  filters : ShowFilters;
}

export interface ChangeShowsFiltersAction {
  type : typeof CHANGE_SHOWS_FILTERS;
  selectedFilters : SelectedShowFilters;
}

export type ShowFiltersActionTypes = 
| SetShowsFiltersAction 
| SetShowsPageAction
| ChangeShowsFiltersAction;

export type ShowsActionTypes = 
| SetShowsAction
| SetShowMediaAction
| SetFeaturedShowsAction;
