export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export interface LoginAction {
  type : typeof LOGIN;
  user : string;
}

export interface LogoutAction {
  type : typeof LOGOUT;
}

export type AuthActionTypes = 
| LoginAction 
| LogoutAction;
