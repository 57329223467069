import { Country, PaginatedData } from "common/models";

export const SET_COUNTRIES_DATA: string = 'SET_COUNTRIES_DATA';
export const SET_COUNTRIES_PAGE: string = 'SET_COUNTRIES_PAGE';


export interface SetCountriesAction {
    type: typeof SET_COUNTRIES_DATA;
    countriesData: PaginatedData<Country>;
}

export interface SetCountriesPageAction {
    type: typeof SET_COUNTRIES_PAGE;
    page: number;
}
  
export type CountryFiltersActionTypes =
  | SetCountriesPageAction;

export type CountriesActionTypes =
  | SetCountriesAction;