import {Tag, PaginatedData, PlayMedia } from "common/models";
import { TagFilters, SelectedTagFilters } from ".";

export const SET_TAGS_DATA = 'SET_TAGS_DATA';
export const SET_TAG_MEDIA = 'SET_TAG_MEDIA';
export const SET_TAGS_PAGE = 'SET_TAGS_PAGE';
export const SET_TAGS_FILTERS = 'SET_TAGS_FILTERS';
export const DELETE_TAG_RELATION = 'DELETE_TAG_RELATION';
export const CHANGE_TAGS_FILTERS = 'CHANGE_TAGS_FILTERS';

export interface SetTagsAction {
  type : typeof SET_TAGS_DATA;
  tagsData : PaginatedData < Tag >;
}

export interface SetTagMediaAction {
  type : typeof SET_TAG_MEDIA;
  media : Array<PlayMedia>;
}

export interface SetTagsPageAction {
  type : typeof SET_TAGS_PAGE;
  page : number;
}

export interface DeleteTagRelationAction {
  type : typeof DELETE_TAG_RELATION;
  mediaId : number;
}

export interface SetTagsFiltersAction {
  type : typeof SET_TAGS_FILTERS;
  filters : TagFilters;
}

export interface ChangeTagsFiltersAction {
  type : typeof CHANGE_TAGS_FILTERS;
  selectedFilters : SelectedTagFilters;
}

export type TagFiltersActionTypes = 
| SetTagsFiltersAction 
| SetTagsPageAction
| ChangeTagsFiltersAction;

export type TagsActionTypes = 
| SetTagsAction
| SetTagMediaAction
| DeleteTagRelationAction;