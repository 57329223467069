import React from 'react';
import {Redirect, RouteProps} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store/configureStore';

const AuthRequired : React.FC < RouteProps > = ({children, location}) => {

    const isAuthenticated = useSelector < AppState,
        boolean > (state => state.auth.isAuthenticated);

    return (<> { isAuthenticated
        ? children
        : <Redirect
            to={{
            pathname: '/login',
            state: {
                redirectAfter: `${location !.pathname}${location !.search}`
            }
        }}/>} </>);
}

export interface LocationAuthState {
    redirectAfter: string
}

export default AuthRequired;