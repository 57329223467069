import axios from "axios";
import { ADMIN_API_HOST } from "config/constants";

const initAxiosRequestInterceptors = () => {
    axios
        .interceptors
        .request
        .use(config => {
            const { token } = localStorage;
            const interceptedConfig = token ? {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                }
            } : config;
            return interceptedConfig;
        }, error => error);
}

const initAxios = () => {
    axios.defaults.baseURL = ADMIN_API_HOST;
    initAxiosRequestInterceptors();
}

export { initAxios };