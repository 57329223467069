import React, {lazy, Suspense} from 'react';
import {Container} from 'react-bootstrap';
import {Route, BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';
import Loading from 'common/components/Loading';
import AuthRequired from 'app/components/AuthRequired';
import {Provider} from 'react-redux';
import {store} from 'redux/store/configureStore';

const Login = lazy(() => import ('views/login'));
const Dashboard = lazy(() => import ('views/dashboard'));
const Media = lazy(() => import ('views/dashboard/views/media'));
const Countries = lazy(() => import ('views/dashboard/views/countries'));
const Categories = lazy(() => import ('views/dashboard/views/categories'));
const Shows = lazy(() => import ('views/dashboard/views/shows'));
const Tags = lazy(() => import ('views/dashboard/views/tags'));

const App : React.FC = () => (
  <Provider store={store}>
    <Suspense fallback={< Loading />}>
      <Container className="App">
        <Router>
          <Switch>
            <Route path="/login" component={Login}/>
            <AuthRequired>
              <Dashboard>
                <Suspense fallback={< Loading />}>
                  <Route path="/media" component={Media}/>
                  <Route path="/country" component={Countries}/>
                  <Route path="/category" component={Categories}/>
                  <Route path="/show" component={Shows}/>
                  <Route path="/tag" component={Tags}/>
                  <Route exact path="/" render={() => (<Redirect to="/media"/>)}/>
                </Suspense>
              </Dashboard>
            </AuthRequired>
          </Switch>
        </Router>
      </Container>
    </Suspense>
  </Provider>
);

export default App;