import {Category, PaginatedData, Show } from "common/models";
import { CategoryFilters, SelectedCategoryFilters } from ".";

export const SET_CATEGORIES_DATA = 'SET_CATEGORIES_DATA';
export const SET_CATEGORY_SHOWS = 'SET_CATEGORY_SHOWS';
export const SET_CATEGORIES_PAGE = 'SET_CATEGORIES_PAGE';
export const SET_CATEGORIES_FILTERS = 'SET_CATEGORIES_FILTERS';
export const CHANGE_CATEGORIES_FILTERS = 'CHANGE_CATEGORIES_FILTERS';

export interface SetCategoriesAction {
  type : typeof SET_CATEGORIES_DATA;
  categoriesData : PaginatedData < Category >;
}

export interface SetCategoryShowsAction {
  type : typeof SET_CATEGORY_SHOWS;
  shows : Array<Show>;
}

export interface SetCategoriesPageAction {
  type : typeof SET_CATEGORIES_PAGE;
  page : number;
}

export interface SetCategoriesFiltersAction {
  type : typeof SET_CATEGORIES_FILTERS;
  filters : CategoryFilters;
}

export interface ChangeCategoriesFiltersAction {
  type : typeof CHANGE_CATEGORIES_FILTERS;
  selectedFilters : SelectedCategoryFilters;
}

export type CategoryFiltersActionTypes = 
| SetCategoriesFiltersAction 
| SetCategoriesPageAction
| ChangeCategoriesFiltersAction;

export type CategoriesActionTypes = 
| SetCategoriesAction
| SetCategoryShowsAction;
