import { TagFiltersActionTypes, SET_TAGS_PAGE, SET_TAGS_FILTERS, CHANGE_TAGS_FILTERS } from "../types/actions";
import { TagFilters } from "../types";

const tagFiltersReducerDefaultState: TagFilters = {
    page: 1,
    selectedCountry: null, 
    countries: []
};

const tagFiltersReducer = (
  state = tagFiltersReducerDefaultState,
  action: TagFiltersActionTypes
): TagFilters => {
  switch (action.type) {
    case SET_TAGS_PAGE:
      return { ...state, page: action.page };
    case SET_TAGS_FILTERS:
      return { ...state, 
        countries: action.filters.countries
      };
    case CHANGE_TAGS_FILTERS:  
      return { ...state, ...action.selectedFilters, page: 1 };
    default:
      return state;
  }
};

export { tagFiltersReducer };