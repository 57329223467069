import { 
  ShowsActionTypes, 
  SET_SHOWS_DATA, 
  SET_SHOW_MEDIA,
  SET_FEATURED_SHOWS 
} from "../types/actions";
import { ShowsData } from "../types";

const showsReducerDefaultState: ShowsData = {
  data: [],
  currentPage: 1,
  lastPage: 1,
  totalCount: 0,
  media: [],
  featuredShows: []
};

const showsReducer = (
  state = showsReducerDefaultState,
  action: ShowsActionTypes
): ShowsData => {
  switch (action.type) {
    case SET_SHOWS_DATA:
      return { ...state, ...action.showsData };
    case SET_SHOW_MEDIA:
      return { 
        ...state, 
        media: action.media
      };
    case SET_FEATURED_SHOWS:
      return { 
        ...state, 
        featuredShows: action.featuredShows
      };
    default:
      return state;
  }
};

export { showsReducer };