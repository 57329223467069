import { CategoriesActionTypes, SET_CATEGORIES_DATA, SET_CATEGORY_SHOWS } from "../types/actions";
import { CategoriesData } from "../types";

const categoriesReducerDefaultState: CategoriesData = {
  data: [],
  currentPage: 1,
  lastPage: 1,
  totalCount: 0,
  shows: []
};

const categoriesReducer = (
  state = categoriesReducerDefaultState,
  action: CategoriesActionTypes
): CategoriesData => {
  switch (action.type) {
    case SET_CATEGORIES_DATA:
      return { ...state, ...action.categoriesData };
    case SET_CATEGORY_SHOWS:
      return { 
        ...state,
        shows: action.shows
      };
    default:
      return state;
  }
};

export { categoriesReducer };