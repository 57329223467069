import { FormFilters, SelectedFormFilters } from ".";

export const SET_FORM_FILTERS = 'SET_FORM_FILTERS';
export const CHANGE_FORM_FILTERS = 'CHANGE_FORM_FILTERS';
export const CLEAR_FORM_FILTERS = 'CLEAR_FORM_FILTERS';

export interface SetFormFiltersAction {
  type : typeof SET_FORM_FILTERS;
  filters : FormFilters;
}

export interface ChangeFormFiltersAction {
  type : typeof CHANGE_FORM_FILTERS;
  selectedFilters : SelectedFormFilters;
}

export interface ClearFormFiltersAction {
  type : typeof CLEAR_FORM_FILTERS;
}

export type FormFiltersActionTypes = 
| SetFormFiltersAction 
| ChangeFormFiltersAction
| ClearFormFiltersAction;

