import {FormFiltersActionTypes, SET_FORM_FILTERS, CHANGE_FORM_FILTERS, CLEAR_FORM_FILTERS} from "../types/actions";
import {FormFilters} from "../types";

const formFiltersReducerDefaultState : FormFilters = {
    selectedCountry: null,
    selectedCategory: null,
    selectedShow: null,
    selectedTags: [],
    countries: [],
    categories: [],
    shows: [],
    tags: []
};

const formFiltersReducer = (
    state = formFiltersReducerDefaultState, 
    action : FormFiltersActionTypes
    ) : FormFilters => {
    switch (action.type) {
        case SET_FORM_FILTERS:
            return {
                ...state,
                ...action.filters
            };
        case CHANGE_FORM_FILTERS:
            return {
                ...state,
                ...action.selectedFilters
            };
        case CLEAR_FORM_FILTERS:
            return {
                ...state,
                ...formFiltersReducerDefaultState,
            };
        default:
            return state;
    }
};

export {formFiltersReducer};