import { PlayMedia, PaginatedData } from "common/models";
import { MediaFilters, SelectedMediaFilters } from ".";

export const SET_MEDIA_DATA = 'SET_MEDIA_DATA';
export const SET_MEDIA_PAGE = 'SET_MEDIA_PAGE';
export const SET_SELECTED_MEDIA = 'SET_SELECTED_MEDIA';
export const CLEAR_SELECTED_MEDIA = 'CLEAR_SELECTED_MEDIA';
export const SET_MEDIA_FILTERS = 'SET_MEDIA_FILTERS';
export const CHANGE_MEDIA_FILTERS = 'CHANGE_MEDIA_FILTERS';

export interface SetMediaAction {
  type : typeof SET_MEDIA_DATA;
  mediaData : PaginatedData < PlayMedia >;
}

export interface SetMediaPageAction {
  type : typeof SET_MEDIA_PAGE;
  page : number;
}

export interface SetSelectedMediaAction {
  type : typeof SET_SELECTED_MEDIA;
  media : PlayMedia;
}

export interface ClearSelectedMediaAction {
  type : typeof CLEAR_SELECTED_MEDIA;
}

export interface SetMediaFiltersAction {
  type : typeof SET_MEDIA_FILTERS;
  filters : MediaFilters;
}

export interface ChangeMediaFiltersAction {
  type : typeof CHANGE_MEDIA_FILTERS;
  selectedFilters : SelectedMediaFilters;
}

export type MediaFiltersActionTypes = 
| SetMediaFiltersAction 
| SetMediaPageAction
| ChangeMediaFiltersAction;

export type MediaActionTypes = 
| SetMediaAction
| SetSelectedMediaAction
| ClearSelectedMediaAction;