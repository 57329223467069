import { MediaFiltersActionTypes, SET_MEDIA_PAGE, SET_MEDIA_FILTERS, CHANGE_MEDIA_FILTERS } from "../types/actions";
import { MediaFilters } from "../types";

const mediaFiltersReducerDefaultState: MediaFilters = {
    page: 1,
    selectedCountry: null,
    selectedCategory: null,
    selectedShow: null,
    selectedTag: null,
    search: '',
    published: null,
    countries: [],
    categories: [],
    shows: [],
    tags: []
};

const mediaFiltersReducer = (
  state = mediaFiltersReducerDefaultState,
  action: MediaFiltersActionTypes
): MediaFilters => {
  switch (action.type) {
    case SET_MEDIA_PAGE:
      return { ...state, page: action.page };
    case SET_MEDIA_FILTERS:
      return { ...state, 
        ...action.filters
      };
    case CHANGE_MEDIA_FILTERS:  
      return { ...state, ...action.selectedFilters, page: 1 };
    default:
      return state;
  }
};

export { mediaFiltersReducer };