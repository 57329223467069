import { Category, Country, Tag, Show, LoginCredentials, PlayMedia } from ".";

export const emptyLoginCredentials: LoginCredentials = {
    email: '',
    password: ''
}

export const emptyCountry: Country = {
    id: 0,
    name: ''
}

export const emptyTag: Tag = {
    id: 0,
    name: '',
    machineName: '',
    countryId: 0
}

export const emptyCategory: Category = {
    id: 0,
    name: '',
    countryId: 0,
    trackingObjectName: ''
}

export const emptyShow: Show = {
    id: 0,
    title: '',
    description: '',
    type: '',
    featuredTitle: '',
    featuredDescription: '',
    isFeatured: false,
    categoryId: 0,
    trackingObjectName: ''
}

export const emptyMedia: PlayMedia = {
    id: 0,
    type: '',
    description: '',
    duration: '',
    credits: '',
    title: '',
    showId: 0,
    status: 0,
    trackingObjectName: '',

}