import {createStore, combineReducers, applyMiddleware, Middleware} from "redux";
import thunk, {ThunkMiddleware} from "redux-thunk";
import { authReducer } from "common/redux/auth/reducers/auth";
import {countriesReducer} from "views/dashboard/views/countries/redux/reducers/countries";
import {countryFiltersReducer} from "views/dashboard/views/countries/redux/reducers/filters";
import { dashboardReducer } from "views/dashboard/redux/reducers/dashboard";
import { categoryFiltersReducer } from "views/dashboard/views/categories/redux/reducers/filters";
import { categoriesReducer } from "views/dashboard/views/categories/redux/reducers/categories";
import { tagsReducer } from "views/dashboard/views/tags/redux/reducers/tags";
import { tagFiltersReducer } from "views/dashboard/views/tags/redux/reducers/filters";
import { mediaReducer } from "views/dashboard/views/media/redux/reducers/media";
import { mediaFiltersReducer } from "views/dashboard/views/media/redux/reducers/filters";
import { showsReducer } from "views/dashboard/views/shows/redux/reducers/shows";
import { showFiltersReducer } from "views/dashboard/views/shows/redux/reducers/filters";
import { formFiltersReducer } from "common/redux/form/reducers/filters";
import { AppActions } from "redux/actions";

export const rootReducer = combineReducers({
    auth: authReducer,
    countries: countriesReducer, 
    countryFilters: countryFiltersReducer,
    categories: categoriesReducer,
    categoryFilters: categoryFiltersReducer,
    tags: tagsReducer,
    tagFilters: tagFiltersReducer,
    shows: showsReducer,
    showFilters: showFiltersReducer,
    media: mediaReducer,
    mediaFilters: mediaFiltersReducer,
    formFilters: formFiltersReducer,
    dashboard: dashboardReducer
});

export type AppState = ReturnType < typeof rootReducer >;

const middlewares: Middleware[] = [thunk as ThunkMiddleware < AppState, AppActions >];
if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));