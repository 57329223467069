import {SHOW_DASHBOARD_LOADING, DashboardActionTypes} from "../types/actions";
import {Dashboard} from "../types";

const dashboardReducerDefaultState : Dashboard = {
  showLoading: false
};

const dashboardReducer = (state = dashboardReducerDefaultState, action : DashboardActionTypes) : Dashboard => {
  switch (action.type) {
    case SHOW_DASHBOARD_LOADING:
      return {
        ...state,
        showLoading: action.showLoading
      };
    default:
      return state;
  }
};

export {dashboardReducer};