import { CountriesActionTypes, SET_COUNTRIES_DATA } from "../types/actions";
import { Country, PaginatedData } from "common/models";

const countriesReducerDefaultState: PaginatedData<Country> = {
  data: [],
  currentPage: 1,
  lastPage: 1,
  totalCount: 0
};

const countriesReducer = (
  state = countriesReducerDefaultState,
  action: CountriesActionTypes
): PaginatedData<Country> => {
  switch (action.type) {
    case SET_COUNTRIES_DATA:
      return { ...state, ...action.countriesData };
    default:
      return state;
  }
};

export { countriesReducer };