import { ShowFiltersActionTypes, SET_SHOWS_PAGE, SET_SHOWS_FILTERS, CHANGE_SHOWS_FILTERS } from "../types/actions";
import { ShowFilters } from "../types";

const showFiltersReducerDefaultState: ShowFilters = {
    page: 1,
    featured: null,
    type: null,
    selectedCountry: null,
    selectedCategory: null,
    countries: [],
    categories: []
};

const showFiltersReducer = (
  state = showFiltersReducerDefaultState,
  action: ShowFiltersActionTypes
): ShowFilters => {
  switch (action.type) {
    case SET_SHOWS_PAGE:
      return { ...state, page: action.page };
    case SET_SHOWS_FILTERS:
      return { ...state, ...action.filters };
    case CHANGE_SHOWS_FILTERS:  
      return { ...state, ...action.selectedFilters, page: 1 };
    default:
      return state;
  }
};

export { showFiltersReducer };