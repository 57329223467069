import {AuthActionTypes, LOGIN, LOGOUT} from "../types/actions";
import {Auth} from "../types";

const authsReducerDefaultState : Auth = {
    isAuthenticated: localStorage.getItem('token') != null,
    user: localStorage.getItem('user')
};

const authReducer = (
    state = authsReducerDefaultState, 
    action : AuthActionTypes
    ) : Auth => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.user
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        default:
            return state;
    }
};

export {authReducer};