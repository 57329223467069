import { 
  TagsActionTypes, 
  SET_TAGS_DATA,
  SET_TAG_MEDIA,
  DELETE_TAG_RELATION
} from "../types/actions";
import { TagsData } from "../types";

const tagsReducerDefaultState: TagsData = {
  data: [],
  currentPage: 1,
  lastPage: 1,
  totalCount: 0,
  media: []
};

const tagsReducer = (
  state = tagsReducerDefaultState,
  action: TagsActionTypes
): TagsData => {
  switch (action.type) {
    case SET_TAGS_DATA:
      return { ...state, ...action.tagsData };
    case SET_TAG_MEDIA:  
      return { 
        ...state, 
        media: action.media 
      };
    case DELETE_TAG_RELATION:
      return { 
        ...state, 
        media: state.media.filter(item => item.id !== action.mediaId)
      };
    default:
      return state;
  }
};

export { tagsReducer };