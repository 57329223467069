import React, {CSSProperties} from 'react';
import {faSpinner} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const overlayStyle : CSSProperties = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    textAlign: "center"
};

const loaderStyle : CSSProperties = {
    position: "relative",
    top: "50%",
    margin: "auto"
};

const Loading : React.FC = () => (
    <div className="loading-overlay" style={overlayStyle}>
        <div className="loader" style={loaderStyle}>
            <FontAwesomeIcon size="6x" spin={true} icon={faSpinner}/>
        </div>
    </div>
);

export default Loading